// New Testnet Contract Addresses
export const AccessControlManager =
  "0x87D3440372293aCf9149552546F7141AAe05Be91";
export const TokenRegistry = "0x032666197A5d9329e717800FC90E8C951bA12290";
export const ProviderRegistry = "0x7fDE3748e8AE27CaF506A47601acAf3B6AEaC34f";
export const ProviderAttributeRegistry =
  "0x87B49A9b928b06AA5782cB7ec495da8c507CaADC";
export const FizzRegistry = "0x6057730B3B355A111061D63d2D7399D8d51aac58";
export const FizzAttributeRegistry =
  "0x24A05B480235Ccb450bf7Ce7e9F65072Ed732292";
export const Escrow = "0x3854Be113E7B1C7fD8CF4805dF6c7f30d1FA1bC6";
export const OrderRequest = "0x1fdf629E5A90eE4FAab1336a23c41A0Cab8CbA9d";
export const ResourceManager = "0x270d622149F46fFc72A9fD5A8cF92D1FdE5AD5E0";
export const ComputeLease = "0x9C0a16a41d2b0fFc14e6f20Da1368b1FD93bD7e5";
export const Bid = "0x27930D6ee40E8DB57c713f063f4a97dc37dC3ffb";
export const ResourceRegistryCPU = "0x67B8c2201Eef00d1a8193a2aF301312DD21a286B";
export const ResourceRegistryGPU = "0x2B73F279044347A3B6c9F122Ec6b479b6C2beAc9";
export const ResourceRegistryFactory =
  "0xbb132eB1b36D87ef7Ac63c95c05a8a56eCDac3fd";
export const ProviderRewardsManager =
  "0x53717Bc3Ba4F84Fd44501b23A92c1F1a3Fc148f0";
export const FizzRewardsManager = "0x92991aCA11aDc8207a397A0bd3Cbc5C8E840b1A5";
export const RewardToken = "0xB28aA1E5074AE0222cf764e2934182775Ee7AB42";
export const FizzNodeRewardToken = "0xA2400EFe3d7a9E2281F32A66Df18CFFE651c69f3";

// New Testnet Token addresses
export const USDT = "0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe";
export const USDC = "0x816413630c39780677E1584D4a6DbAFd76b161F7";
export const DAI = "0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892";
export const WETH = "0xd74C08f8ffDF88C807367813Ad64a960618f4dcC";
export const CST = "0x8E5996A3963011Ca9dFa04E3CB773147F98C0e17";
export const SPON = "0x76e593392523243ACD38ceD87c2007F14483a86B";
